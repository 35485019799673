import "./styles.css";

import PartySocket from "partysocket";

declare const PARTYKIT_HOST: string;

// Let's append all the messages we get into this DOM element
const output = document.getElementById("message-output") as HTMLDivElement;

// Helper function to add a new line to the DOM
function add(text: string) {
  output.appendChild(document.createTextNode(text));
  output.appendChild(document.createElement("br"));
}

// A PartySocket is like a WebSocket, except it's a bit more magical.
// It handles reconnection logic, buffering messages while it's offline, and more.
const conn = new PartySocket({
  host: PARTYKIT_HOST,
  room: "my-new-room",
});

// You can even start sending messages before the connection is open!
conn.addEventListener("message", (event) => {
  add(`Received -> ${event.data}`);
});

// Let's listen for when the connection opens
// And send a ping every 2 seconds right after
conn.addEventListener("open", () => {
  add("Connected to chat!");

  const messageInput = document.getElementById("message-input") as HTMLDivElement
  const sendButton = document.getElementById("send-button") as HTMLDivElement

  const myInput = document.createElement("input");
  myInput.setAttribute("type", "text");
  myInput.setAttribute("id", "input-text");
  messageInput?.appendChild(myInput);

  const myButton = document.createElement("input");
  myButton.setAttribute("type", "button");
  myButton.setAttribute("value", "Send");
  sendButton?.appendChild(myButton)

  myButton.onclick = () => {
    conn.send((myInput as HTMLInputElement)?.value);
    add(`Sent -> ${(myInput as HTMLInputElement)?.value}`);
    (myInput as HTMLInputElement).value = "";
  };

});
